import { Subject } from "rxjs";

const subject = new Subject();

let dataStream = {
  access_key: "",
  profile_id: "",
  transaction_uuid: "",
  reference_number: "",
  signed_date_time: new Date(),
  signature: "",
  amount: "",
  currency: "",
};
const initialstate = {
  access_key: " ",
  profile_id: "",
  transaction_uuid: "",
  reference_number: "",
  signed_date_time: new Date(),
  signature: "",
  amount: "",
  currency: "",
};

const cardStore = {
  init: () => {
    dataStream = initialstate;
    subject.next(dataStream);
  },
  pushData: (incomer) => {
    dataStream.access_key = incomer.access_key;
    dataStream.amount = incomer.amount;
    dataStream.signed_date_time = incomer.signed_date_time;
    dataStream.currency = incomer.currency;
    dataStream.profile_id = incomer.profile_id;
    dataStream.signature = incomer.signature;
    dataStream.reference_number = incomer.reference_number;
    dataStream.transaction_uuid = incomer.transaction_uuid;
    subject.next(dataStream);
  },
  clearPdata: () => subject.next(),
  getPdata: () => subject.asObservable(),
};

export default cardStore;
