import React from "react";
import { Button, Typography } from "@material-tailwind/react";
const DonationLeftSection = () => {
  return (
    <div className=" lg:mr-5    bg-white flex flex-col h-full w-[320px] md:w-[481px] lg:rounded-2xl flex-shrink-0 relative rounde">
      <img
        src="../images/DonationCover.jpg"
        alt="Donate Cover"
        style={{
          objectFit: "cover",
          objectPosition: "50% 50%",
        }}
        loading="lazy"
        className="w-full bg-no-repeat bg-cover rounded-tl-2xl rounded-tr-2xl h-72"
      />
      <div className="pt-3 px-7 pb-2 w-full flex flex-shrink-0 self-center h-[70px]  ">
        <img
          src="../images/LogoTitle.png"
          alt="Donate Log"
          style={{
            height: 50,
            width: "auto",
            objectFit: "contain",
          }}
        />
      </div>
      <div className="flex flex-col flex-grow order-2 pb-4 overflow-auto px-7 sm:pb-8">
        <Typography
          variant="h5"
          color="blue-gray"
          className="align-left font-NokiaBold"
        >
          በአገልግሎቱ ይሳተፋ የአእላፋት ዝማሬን ይደግፋ
        </Typography>
        <Typography
          variant="paragraph"
          className="mt-2 overflow-hidden font-normal leading-5 select-text font-NokiaBold align-left "
        >
          የጌታችን የመድኃኒታችንን የኢየሱስ ክርስቶስ የልደት በዓልን በአእላፋት ዝማሬ ለማክበር ኑ ይቀላቀሉን። ያግዙን።
          &nbsp;
          <br />
          <br />
          <Typography
            variant="h4"
            color="#65081B"
            className="font-NokiaBold text-primary"
          >
            እንዳልዘምር የሚከለክለኝ ምንድን ነው?
          </Typography>
        </Typography>
      </div>
    </div>
  );
};

export default DonationLeftSection;
