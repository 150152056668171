import React, { useState } from "react";
import {
  Input,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
  Checkbox,
  Typography,
} from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

const DonationInput = ({ formData, changeAmount }) => {
  const [currency, setCurrency] = useState("USD");
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const currencys = [" USD · US Dollar"];
  return (
    <div className="flex flex-col">
      <div className="relative flex">
        <Input
          type="number"
          placeholder="Other"
          value={formData.amount}
          onChange={(e) => changeAmount(e.target.value)}
          className="rounded-r-none    !border-t-blue-gray-200 shadow-lg  ring-4 ring-transparent  focus:!border-blue-500 focus:ring-blue-500/20 "
          labelProps={{
            className: "before:content-none after:content-none",
          }}
          containerProps={{
            className: "!min-w-0 !flex-grow ",
          }}
        />
        <Menu>
          <MenuHandler>
            <Button
              ripple={false}
              variant="text"
              color="blue-gray"
              className="flex items-center h-10 gap-1 pr-3 border border-l-0 rounded-l-none border-blue-gray-200 bg-blue-gray-500/10"
            >
              {currency}
              <ChevronDownIcon className="h-5 text-blue-500" />
            </Button>
          </MenuHandler>
          <MenuList className="max-h-[20rem] max-w-[18rem]">
            {currencys.map((item, index) => {
              return <MenuItem>{item}</MenuItem>;
            })}
          </MenuList>
        </Menu>
      </div>
      {/* <Checkbox
        checked={isChecked}
        onChange={handleCheckboxChange}
        label={
          <Typography color="blue-gray" className="flex font-medium">
            Dedicate this donation
          </Typography>
        }
        containerProps={{
          className: "mt-3 mb-3",
        }}
        labelProps={{
          className: "mt-3 mb-3",
        }}
      />
      {isChecked ? <Input label="Name of someone special to me" /> : ""} */}
    </div>
  );
};

export default DonationInput;
