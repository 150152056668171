import React, { useEffect, ReactNode, useState } from "react";
import ModalComponent from "../components/ModelComponent";
import Donation from "../components/Donation";
import HeroSecton from "../components/HeroSection";
const HomePage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const donationParam = searchParams.get("form") === "donate";
    console.log(donationParam, searchParams);
    setModalIsOpen(donationParam);
  }, []);

  const closeModal = () => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete("form");
    window.history.pushState({}, "", currentUrl);
    setModalIsOpen(false);
  };

  const openModal = () => setModalIsOpen(true);
  return (
    <div>
      <HeroSecton openModal={openModal} />
      {modalIsOpen && (
        <ModalComponent isOpen={modalIsOpen} onRequestClose={closeModal}>
          <Donation />
        </ModalComponent>
      )}
    </div>
  );
};

export default HomePage;
