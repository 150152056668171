import React, { useEffect } from "react";
import DonationInput from "../DonationInput";
import DonationSelector from "../DonationSelector";
import {
  Button,
  IconButton,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import cardStore from "../../../Service/cybst.service";
const CyberSourceForm = ({ formData }) => {
  return (
    <div className="form-group">
      <input
        className="form-control"
        type="text"
        name="access_key"
        required
        hidden
        value={formData.access_key}
        // onChange={this.onChangeAccesskey}
      />
      <input
        className="form-control"
        type="number"
        name="amount"
        required
        hidden
        value={formData.amount}
        // // onChange={this.onChangeAmount}
      />
      <input
        className="form-control"
        type="text"
        name="currency"
        hidden
        value={formData.currency}
        // // onChange={this.onChangeCurrency}
      />
      <input
        className="form-control"
        type="text"
        name="payment_method"
        required
        hidden
        value={formData.payment_method}
        // // onChange={this.onChangeCurrency}
      />
      <input
        className="form-control"
        type="text"
        name="locale"
        required
        hidden
        value={formData.locale}
        // // onChange={this.onChangeCurrency}
      />
      <input
        className="form-control"
        type="text"
        name="profile_id"
        required
        hidden
        value={formData.profile_id}
        // // onChange={this.onChangeProfileID}
      />
      <input
        className="form-control"
        type="text"
        name="transaction_uuid"
        required
        hidden
        value={formData.transaction_uuid}
        // // onChange={this.onChangeTransactionUuid}
      />
      <input
        className="form-control"
        type="text"
        name="signed_field_names"
        required
        hidden
        value={formData.signed_field_names}
        // // onChange={this.onChangeCurrency}
      />
      <input
        className="form-control"
        type="text"
        name="unsigned_field_names"
        hidden
        value={formData.unsigned_field_names}
        // // onChange={this.onChangeCurrency}
      />
      <input
        className="form-control"
        type="text"
        name="signed_date_time"
        required
        hidden
        value={formData.signed_date_time}
        // // onChange={this.onChangeDate}
      />
      <input
        className="form-control"
        type="text"
        name="transaction_type"
        required
        hidden
        value={formData.transaction_type}
        // // onChange={this.onChangeCurrency}
      />
      <input
        className="form-control"
        type="text"
        name="reference_number"
        required
        hidden
        value={formData.reference_number}
        // // onChange={this.onChangeReferenceNum}
      />

      <input
        className="form-control"
        type="text"
        name="signature"
        hidden
        value={formData.signature}
      />
    </div>
  );
};
const DonationSecondSection = ({
  onNext,
  onPrev,
  donationState,
  handleDonationState,
}) => {
  useEffect(() => {
    const subscription = cardStore.getPdata().subscribe((pstore) => {
      if (pstore) {
        const updateForm = {
          access_key: pstore.access_key,
          profile_id: pstore.profile_id,
          transaction_uuid: pstore.transaction_uuid,
          reference_number: pstore.reference_number,
          signed_date_time: pstore.signed_date_time,
          signature: pstore.signature,
          amount: pstore.amount,
          currency: pstore.currency,
        };
        handleDonationState(updateForm);
      }
    });
  });
  return (
    <div className="  w-[320px] md:w-[481px] lg:w-[338px] bg-white flex flex-col lg:rounded-2xl h-[580px] justify-between">
      <div>
        <div className="px-4 pt-4 ">
          <div className="flex flex-row items-center ">
            <IconButton
              variant="outlined"
              size="sm"
              className="border-none rounded-none"
              onClick={onPrev}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="block icon-stroke "
                aria-hidden="true"
                data-testid="back-icon"
              >
                <path
                  d="M16.5 9L11.5 14L16.5 19"
                  stroke="#65081B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </IconButton>
            <Typography
              variant="lead"
              className="w-full font-medium text-center font-Brandon"
            >
              Payment option
            </Typography>
          </div>
        </div>
        <div className="mt-2 mb-6 border-t border-gray-300" />
        <div className="px-9 ">
          <Typography
            variant="h4"
            className="w-full font-medium text-center font-Brandon"
          >
            {donationState.amount} {donationState.currency}/Once
          </Typography>
          {/* <Typography
            variant='h6'
            className='flex flex-row items-center justify-center w-full font-medium font-bold font-Brandon'
          >
            Transaction costs
            <Tooltip
              content={
                <div className='w-80'>
                  <Typography color='white' className='font-medium'>
                    Material Tailwind
                  </Typography>
                  <Typography variant='small' color='white' className='font-normal opacity-80'>
                    Material Tailwind is an easy to use components library for Tailwind CSS and Material Design.
                  </Typography>
                </div>
              }
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                strokeWidth={2}
                className='w-5 h-5 cursor-pointer text-blue-gray-500'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
                />
              </svg>
            </Tooltip>
          </Typography> */}
        </div>
      </div>
      <div className="flex flex-col gap-3 mx-8 mb-7">
        <form
          id="payment_confirmation"
          action="https://secureacceptance.cybersource.com/pay"
          method="post"
          className="w-full"
        >
          <CyberSourceForm formData={donationState} />
          <Button
            type="submit"
            id="submit"
            value="Confirm"
            size="sm"
            color="white"
            className="flex items-center justify-center w-full gap-3 text-white bg-primary"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="block"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.875 6C1.875 5.37868 2.37868 4.875 3 4.875H21C21.6213 4.875 22.125 5.37868 22.125 6V9.125H1.875V6ZM0.125 10V6C0.125 4.41218 1.41218 3.125 3 3.125H21C22.5878 3.125 23.875 4.41218 23.875 6V10V18C23.875 19.5878 22.5878 20.875 21 20.875H3C1.41218 20.875 0.125 19.5878 0.125 18V10ZM22.125 10.875V18C22.125 18.6213 21.6213 19.125 21 19.125H3C2.37868 19.125 1.875 18.6213 1.875 18V10.875H22.125Z"
                fill="currentColor"
              ></path>
            </svg>
            Internation Card
          </Button>
        </form>

        {/* <Button
          size="sm"
          className="  !bg-white flex items-center gap-3 justify-center  border-black  text-white"
          onClick={onNext}
        >
          <img
            src="https://www.ethiotelecom.et/wp-content/uploads/2021/04/TeleBirr-Logo.svg"
            title="TeleBirr-Logo"
            alt="TeleBirr-Logo"
            className="w-full h-10 "
            // width={30}
          />
        </Button>
        <Button
          size="sm"
          className="  !bg-green-800 flex items-center gap-3 justify-center bg-white border-black  text-white"
          onClick={onNext}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
            />
          </svg>
          Transfer
        </Button> */}
      </div>
    </div>
  );
};

export default DonationSecondSection;
