import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DonationForm from "./pages/DonationForm";
import ConfirmationPage from "./pages/ConfirmationPage";
import Donation from "./components/Donation";
import HomePage from "./pages/HomePage";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Helmet, HelmetProvider } from "react-helmet-async";
const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <Analytics />
        <SpeedInsights />
        <Helmet>
          <title> እንዳልዘምር የሚከለክለኝ ምንድን ነው?</title>
          <meta
            name="description"
            content="የኢትዮጵያዊው ጃንደረባ ትውልድ ታኅሣሥ 27 ቀን 2016 ዓ.ም የጌታችን የመድኃኒታችን የኢየሱስ ክርስቶስ የልደት በዓል ዋዜማ በብዙ ሺዎች የሚቆጠሩ ምእመናን የሚሳተፉበት 'የአእላፋት ዝማሬ' የተሰኘ የአደባባይ ዝማሬ ዝግጅት ያከናውናል። ይህንን አገልግሎት ለመደገፍ የሚከተለውን ሊንክ በመጠቀም የበረከቱ"
          />
        </Helmet>
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/test" element={<HomePage />} /> */}
          {/* <Route path="/old" element={<DonationForm />} /> */}
          {/* <Route path="/confirm" element={<ConfirmationPage />} /> */}
        </Routes>
      </Router>
    </HelmetProvider>
  );
};

export default App;
