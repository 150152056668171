import { Button, Typography } from "@material-tailwind/react";
import React from "react";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { Link } from "react-router-dom";

const HeroSecton = ({ openModal }) => {
  return (
    <div className="space-y-24 bg-gray-100 ">
      <div className="xl:relative" style={{ background: "#4a3f39" }}>
        <div className="relative grid items-end overflow-hidden md:min-h-screen motion-safe:lg:animate-fade-in motion-safe:lg:animation-delay-300 motion-safe:lg:opacity-0 ">
          <picture className="block w-full h-auto align-middle ">
            <img
              className="absolute inset-0 object-cover w-full h-full "
              // src="https://res.cloudinary.com/drovj141n/image/upload/v1700988850/JAN%20WEb/steptodown.com603071_ci82ty.jpg"
              src="https://res.cloudinary.com/drovj141n/image/upload/v1703623253/LI_gch2ql.jpg"
              alt="HeroImage"
              loading="lazy"
              style={{ aspectRatio: "1600/1004" }}
            />
          </picture>
          <div className="absolute top-0 left-0 w-full h-full max-h-full transition-opacity pointer-events-none md:h-64 bg-gradient-black-fade-down md:max-h-80"></div>
          <div className="relative left-0 right-0 flex flex-col items-center gap-16 pt-24 pb-8 pointer-events-none px-7 md:flex-row md:items-end md:pb-8 xl:px-16">
            <div className="absolute bottom-0 left-0 w-full !h-full !sm:h-32 sm:h-full bg-gradient-black-fade-up md:h-auto "></div>
            {/* <div className='relative flex-grow pt-40 space-y-4 text-center md:pt-16 md:text-left'> */}
            <div className="relative flex flex-col items-center justify-center flex-grow space-y-6 text-center md:items-start md:pt-32 md:text-left">
              <Typography
                variant="h1"
                className=" font-NokiaBold max-w-[36rem] drop-shadow-homepage-hero"
              >
                እንዳልዘምር የሚከለክለኝ ምንድን ነው?
              </Typography>
              <FlipClockCountdown
                className=" flip-clock w-fit"
                to={new Date("Jan 6, 2024 16:00:00 GMT+0300")}
                labels={["ቀን ", "ሰዓት", "ደቂቃ", "ሰከንድ"]}
                labelStyle={{
                  // fontSize: 10,
                  fontWeight: 900,
                  fontFamily: "NokiaPureTextBold",
                  color: "white",
                  textTransform: "uppercase",
                }}
                digitBlockStyle={{
                  // width: 40,
                  // height: 60,
                  // fontSize: 30,
                  background: "#F3C811",
                  color: "#65081B",
                  fontFamily: "NokiaPureTextBold",
                }}
                dividerStyle={{ color: "#65081B", height: 1 }}
                duration={0.5}
                // separatorStyle={{ color: "red", size: "6px" }}
              />

              <Typography
                variant="lead"
                className="block text-white font-NokiaBold"
              >
                የኢትዮጵያዊው ጃንደረባ ትውልድ ታኅሣሥ 27 ቀን 2016 ዓ.ም የጌታችን የመድኃኒታችን የኢየሱስ
                ክርስቶስ የልደት በዓል ዋዜማ በብዙ ሺዎች የሚቆጠሩ ምእመናን የሚሳተፉበት "የአእላፋት ዝማሬ" የተሰኘ
                የአደባባይ ዝማሬ ዝግጅት ያከናውናል። ይህንን አገልግሎት ለመደገፍ የሚከተለውን ሊንክ በመጠቀም
                የበረከቱ ተካፋይ ይሁኑ።
              </Typography>
              {/* <div class="relative inset-0 bg-black bg-opacity-50 md:hidden flex justify-center items-center rounded">
                <span class="text-white p-2 font-NokiaBold">
                  የኢትዮጵያዊው ጃንደረባ ትውልድ - ጃን ያሬድ የኅብረ ዝማሬ አገልግሎት ክፍል በ2016 ዓ.ም.
                  የመድኃኔዓለም ክርስቶስ የልደት በዓል ዋዜማ በብዙ ሺህዎች የሚቆጠሩ ምእመናን የሚሳተፉበት
                  "የአእላፋት ዝማሬ" የተሰኘ የአደባባይ ዝማሬ ዝግጅት ሊያከናውናል። ይህንን የበረከት ስራ ለመደገፍ
                  የሚከተለወን ሊንክ በመጠቀም አስተዋፆ ያድራጉ።
                </span>
              </div> */}
            </div>
            <div className="relative flex flex-col items-center flex-shrink-0 space-y-4 pointer-events-auto max-w-256 ">
              <div className="">
                <Link to="?form=donate">
                  <Button
                    size="lg"
                    color="white"
                    onClick={openModal}
                    className=" text-primary  bg-secondary hover:bg-transparent !font-NokiaBold rounded-none shadow-none  lg:py-[22px] px-6 lg:px-7 lg:text-lg
                     text-center  uppercase button-primary-hover "
                  >
                    አገልግሎቱን ይደግፉ
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSecton;
