import React, { useState, useEffect } from "react";
import { Button, Typography } from "@material-tailwind/react";
import DonationSelector from "./Donation/DonationSelector";
import DonationInput from "./Donation/DonationInput";
import DonationLeftSection from "./Donation/DonationLeftSection";
import DonationFirstSection from "./Donation/DonationRightSection/DonationFirstSection";
import DonationSecondSection from "./Donation/DonationRightSection/DonationSecondSection";
import { useTransition, animated, config } from "react-spring";

const Donation = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [donationState, setDonationState] = useState({
    payment_method: "",
    transaction_type: "sale",
    locale: "en",
    signed_field_names:
      "access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency",
    unsigned_field_names: "",
    access_key: "bc0bb02dc95f38c985af823e385277bf",
    profile_id: "12B46979-EC14-4E32-960D-0F035C855426",
    transaction_uuid: "",
    reference_number: "3942263",
    signed_date_time: null,
    signature: "",
    amount: "",
    currency: "",
  });
  const handleDonationState = (updateStatus) => {
    setDonationState({ ...donationState, ...updateStatus });
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };
  const handlePerviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const rightSectionComponents = [
    {
      key: 1,
      component: (
        <DonationFirstSection
          onNext={handleNextStep}
          onPrev={handlePerviousStep}
          donationState={donationState}
          handleDonationState={handleDonationState}
        />
      ),
    },
    {
      key: 2,
      component: (
        <DonationSecondSection
          onNext={handleNextStep}
          onPrev={handlePerviousStep}
          donationState={donationState}
          handleDonationState={handleDonationState}
        />
      ),
    },
  ];
  const transitions = useTransition(
    rightSectionComponents.find((item) => item.key === currentStep),
    {
      key: (item: any) => item.key,
      from: { opacity: 0, transform: "translateX(100%)" },
      enter: { opacity: 1, transform: "translateX(0%)" },
      leave: { opacity: 0, transform: "translateX(-100%)" },
    }
  );
  return (
    <div className="relative z-50 flex flex-col overflow-hidden w-fit lg:flex-row ">
      <div className="">
        <DonationLeftSection />
        <div className="w-full border-t lg:hidden h-1/2 border-primary"></div>
      </div>
      <div className="lg:w-[338px] flex-1   ">
        {transitions((style, item) => (
          <animated.div style={{ ...style, width: "100%", height: "100%" }}>
            <div className="h-full w-fit ">{item?.component}</div>
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default Donation;
