import React from "react";
import Modal from "react-modal";

const ModalComponent = ({ isOpen, onRequestClose, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Modal"
      className="modal"
      overlayClassName="custom-modal-overlay"
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;
