import React, { useState } from "react";
import DonationInput from "../DonationInput";
import DonationSelector from "../DonationSelector";
import { Button, Typography } from "@material-tailwind/react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@material-tailwind/react";

const DonationFirstSection = ({ onNext, handleDonationState }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    access_key: "",
    profile_id: "",
    amount: "100",
    currency: "USD",
    locale: "en",
    payment_method: "card",
    refrence_number: uuidv4(),
    signed_date_time: "",
    signed_field_names: [
      "access_key",
      "amount",
      "currency",
      "locale",
      "payment_method",
      "profile_id",
      "reference_number",
      "signed_date_time",
      "signed_field_names",
      "transaction_type",
      "transaction_uuid",
      "unsigned_field_names",
    ],
    transaction_type: "sale",
    transaction_uuid: uuidv4(),
    unsigned_field_names: "",
    signature: "",
    customerIp: "127.0.0.0",
  });
  const changeAmount = (value) => {
    console.log(value);
    setFormData({ ...formData, amount: parseInt(value) });
  };
  const onChangeAmount = (e) => {
    e.preventDefault();
    setFormData({ ...formData, amount: e.target.value });
  };
  const onChangeCurrency = (e) => {
    e.preventDefault();
    setFormData({ ...formData, currency: e.target.value });
  };

  const onSubmit = () => {
    setIsLoading(true);
    const fields = {
      access_key: formData.access_key,
      locale: formData.locale,
      payment_method: formData.payment_method,
      profile_id: formData.profile_id,
      signature: formData.signature,
      signed_date_time: formData.signed_date_time,
      signed_field_names: formData.signed_field_names,
      transaction_type: formData.transaction_type,
      unsigned_field_names: formData.unsigned_field_names,
      reference_number: formData.reference_number,
      amount: formData.amount,
      currency: formData.currency,
    };

    axios
      .post("https://donation-backend.azurewebsites.net/hasher", fields)
      .then((res) => {
        const updateForm = {
          access_key: res.data.access_key,
          locale: res.data.locale,
          payment_method: res.data.payment_method,
          profile_id: res.data.profile_id,
          signature: res.data.signature,
          signed_date_time: res.data.signed_date_time,
          signed_field_names: res.data.signed_field_names,
          transaction_type: res.data.transaction_type,
          transaction_uuid: res.data.transaction_uuid,
          unsigned_field_names: res.data.unsigned_field_names,
          reference_number: res.data.reference_number,
          amount: res.data.amount,
          currency: res.data.currency,
        };
        handleDonationState(updateForm);
        onNext();
      });
  };
  return (
    <div className="  w-[320px] md:w-[481px] lg:w-[338px] bg-white  !h-full flex flex-col lg:rounded-2xl    justify-between">
      <div>
        <div className="p-4">
          <div className="flex flex-row items-center justify-center pt-3 pb-2">
            <svg
              fill="none"
              height="40"
              viewBox="0 0 28 28"
              width="40"
              xmlns="http://www.w3.org/2000/svg"
              className="block mr-2"
            >
              <g fill="#00c07b">
                <path
                  clip-rule="evenodd"
                  d="m15.0393 2.44995c-.6707-.24772-1.4079-.24772-2.0786 0l-8.30715 3.06797c-.39274.14504-.65355.51939-.65355.93807v7.54411c0 3.6861 1.95965 6.6874 4.28911 8.8073 1.16017 1.0557 2.38789 1.8689 3.45309 2.4137 1.1081.5668 1.9145.779 2.2578.779s1.1497-.2122 2.2578-.779c1.0652-.5448 2.2929-1.358 3.4531-2.4137 2.3294-2.1199 4.2891-5.1212 4.2891-8.8073v-7.54411c0-.41868-.2608-.79303-.6536-.93807zm-2.7715-1.876139c1.1179-.412868 2.3465-.412868 3.4644 0l8.3071 3.067969c1.1783.43514 1.9607 1.55819 1.9607 2.81421v7.54411c0 4.4389-2.3618 7.9375-4.943 10.2865-1.2952 1.1786-2.6702 2.092-3.8885 2.7151-1.1754.6012-2.3332.9984-3.1685.9984s-1.9931-.3972-3.1685-.9984c-1.21831-.6231-2.59328-1.5365-3.88847-2.7151-2.58125-2.349-4.94303-5.8476-4.94303-10.2865v-7.54411c0-1.25602.78243-2.37907 1.96066-2.81421z"
                  fill-rule="evenodd"
                ></path>
                <path d="m18.2906 11.75h-.2535v-1.1855c0-2.19278-1.7415-4.02451-3.9182-4.06361-.0595-.00107-.1783-.00107-.2378 0-2.1767.0391-3.91819 1.87083-3.91819 4.06361v1.1855h-.25354c-.39069 0-.70937.4028-.70937.9003v5.9463c0 .4969.31868.9035.7094.9035h8.5812c.3907 0 .7094-.4066.7094-.9035v-5.9463c0-.4974-.3187-.9003-.7094-.9003zm-3.4867 3.8674v1.7967c0 .2058-.1723.3799-.3784.3799h-.8509c-.2061 0-.3785-.1741-.3785-.3799v-1.7967c-.1999-.1966-.3162-.4684-.3162-.7691 0-.5698.4408-1.0594 1.0013-1.082.0594-.0024.1783-.0024.2377 0 .5605.0226 1.0013.5122 1.0013 1.082 0 .3007-.1164.5725-.3163.7691zm1.5623-3.8674h-4.7323v-1.1855c0-1.30621 1.0623-2.38623 2.3661-2.38623s2.3662 1.08002 2.3662 2.38623z"></path>
              </g>
            </svg>
            <Typography variant="lead">Secure donation</Typography>
          </div>
        </div>
        <div className="px-9">
          <DonationSelector changeAmount={changeAmount} formData={formData} />
          <DonationInput changeAmount={changeAmount} formData={formData} />
        </div>
      </div>
      <Button
        className="mx-8 mb-7 bg-primary hover:shadow-secondary"
        disabled={isLoading}
        onClick={onSubmit}
      >
        {isLoading ? (
          // <CircularProgress color="white" size={24} />
          <Spinner className="w-12 h-12 mx-auto" color="#F3C811" />
        ) : (
          "Donate Once"
        )}
      </Button>
    </div>
  );
};

export default DonationFirstSection;
