import React, { useMemo } from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Button,
  ButtonGroup,
} from "@material-tailwind/react";

const DonationSelector = ({ changeAmount, formData }) => {
  const data = [
    {
      label: "Give Once",
      value: "Give Once",
      desc: ["70000", "12000", "7000", "2500", "650", "350"],
    },
    // {
    //   label: "Monthly",
    //   value: "Monthly",
    //   desc: ["7000", "3500", "1200", "650", "350", "300"],
    // },
  ];

  const formattedCount = useMemo(
    () =>
      new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
      }),
    []
  );
  return (
    <Tabs value="Give Once">
      <TabsHeader
        className="z-0 p-0 bg-white border border-gray-300 "
        indicatorProps={{
          className:
            "bg-white  text-blue-500 shadow-lg  border border-blue-900  border-2 z-10  ",
        }}
      >
        {data.map(({ label, value }) => (
          <Tab key={value} value={value} className="text-lg">
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, desc }) => (
          <TabPanel key={value} value={value} className="px-0">
            <div className="flex  flex-wrap  items-start gap-[5px] ">
              <React.Fragment key={value}>
                {desc.map((item, index) => (
                  // <div key={index}>{`Description ${index + 1}: ${item}`}</div>
                  <Button
                    variant="outlined"
                    className="border-none shadow-none donateBtn "
                    onClick={() => changeAmount(item)}
                  >
                    {formattedCount.format(item)}
                  </Button>
                ))}
              </React.Fragment>
            </div>
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
};

export default DonationSelector;
